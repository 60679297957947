/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, namespace, State } from 'vuex-class';
import { ModelAccountState } from '@/store/modelAccount/types';
import { modelAccount } from '@/store/modelAccount';
import { Confidentiality } from '@/components/pages/model-account/my-account-confidentiality/model-account-my-account-confidentiality.interface';
import { ValidPassword } from '@/services/validation.service';
import { CustomerState } from '@/store/customer/types';

const customer = namespace('customer');
const modelState = namespace('modelAccount');


export default class ModelAccountMyAccountСonfidentialityPage extends Vue {

	@modelState.State('modelAccount') modelAccountState: ModelAccountState | undefined;
	@customer.State('customer') customerState: CustomerState | undefined;
	@modelState.Action('renewConfidentiality') renewConfidentiality: any;
	@modelState.Action('getConfidentiality') getConfidentiality: any;
	@modelState.Getter('getDataConfidentiality') getDataConfidentiality: any;
	@customer.Action('changePassword') changePassword: any;

	confidentiality: Confidentiality = {
		countries: '',
		nameStates: '',
		cities: ''
	}

	userOldPassword: string = '';
	userNewPassword: string = '';
	userRepeatPassword: string ='';
	userRememberPassword: boolean = false;

	changeUserPassword() {
		// console.log('OK')
		if(ValidPassword(this.userNewPassword) && this.userNewPassword === this.userRepeatPassword) {
			this.changePassword({
				oldPassword: this.userOldPassword,
				newPassword: this.userNewPassword
			})
			this.userOldPassword = '';
			this.userNewPassword = '';
			this.userRepeatPassword = '';
		}
	}

	onRenew() {
		this.renewConfidentiality({
			country: this.confidentiality.countries,
			region: this.confidentiality.nameStates,
			city: this.confidentiality.cities
		});
		this.confidentiality = {
			countries: '',
			nameStates: '',
			cities: ''
		}
	}

	async mounted() {
		await this.getConfidentiality().then(
			this.confidentiality.countries = this.getDataConfidentiality.country,
			this.confidentiality.nameStates = this.getDataConfidentiality.region,
			this.confidentiality.cities = this.getDataConfidentiality.city
		)
	}

}
